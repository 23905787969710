






















































import Vue from 'vue';
import ComponentLogin from '@/components/Login.vue';
export default Vue.extend({
  'name': 'Login',
  'components': {
    ComponentLogin
  }
});
